import {
	SELECT_ERRAND_IN_ERRAND_LIST,
	SELECT_ALL_ERRANDS_IN_ERRAND_LIST,
	SELECT_ALL_ERRANDS_WITHOUT_CHAT,
	SELECT_AREA_IN_AREA_LIST,
	SELECT_ALL_AREAS,
	SELECT_TOGGLE_SORT_ERRAND_LIST,
	SELECT_TOGGLE_PRIORITY_FILTER,
	SELECT_TOGGLE_AREAS_FILTER,
	SELECT_TOGGLE_AGENTS_FILTER,
	SELECT_TOGGLE_TAGS_FILTER,
	CHANGE_CONTEXT,
	CHANGE_SELECTED_FILTER_AREAS,
	CLEAR_MANUAL_ERRAND_INPUTS,
	CLEAR_MANUAL_CALL_INPUTS,
	MANUAL_ERRAND_BUSY,
	MANUAL_CALL_BUSY,
	NORMALIZE_AGENTS,
	SET_MY_ERRANDS_FOLDER,
	SET_SELECTED_FOLDER,
	SELECT_TOGGLE_SIDEBAR,
	SELECT_COLLAPSE_SIDEBAR,
	SELECT_LAUNCHPAD_LAYOUT,
	UPDATE_LAUNCHPAD_WIDGET,
	ADD_LAUNCHPAD_WIDGET,
	DELETE_LAUNCHPAD_WIDGET,
	UPDATE_LAUNCHPAD_GRID_LAYOUT,
	SET_SELECTED_AGENT,
	SET_SELECTED_TAGS,
	SET_MOBILE_VIEW,
	SET_ERRAND_MOBILE_VIEW,
	SET_WORKFLOW_READY,
	UPDATE_PRIORITY_SORT,
	UPDATE_WF_PARAMS,
	SET_LIST_READY,
	SET_AGENTDATA_FROM_LOCAL,
	TOGGLE_WF_POPUP,
	RESET_ERRAND_VIEW,
	CONTROL_ERRAND_MULTIPLE_ACTION,
	SET_FILTERED_TAGS,
	SET_FILTERED_AREAS,
	RESET_WORKFLOW_FILTER,
	SHOW_WORKFLOW_MULTIPLE_ACTIONS,
	CHANGE_ERRAND_LIST_VIEW_MODE,
	SET_SEARCH_PREVIEW_ID,
	SET_ERRAND_MESSAGE_TRUNCATE,
	SET_WFSETTINGS_FROM_LOCAL,
	PM_SUBCRIBE_ERRAND_COUNT,
	SHOW_ALL_AREA_IN_AREA_FILTER
} from '../constants/constants';
import {
	CTX_NEW,
	ME_ST_BUSY,
	ME_ST_CREATED,
	ME_ST_IDLE,
	MP_CALL,
	MP_BASIC_CALL,
	MY_ERRANDS,
	PREF_CONVERSATION_VIEW,
	emptyArray,
	WFP_MANUAL_ERRAND,
	WFP_BULK_SEND_ERRAND,
	MP_NONE,
	MP_MINIMIZE,
	MP_MAXIMIZE,
	WFP_MANUAL_CALL
} from '../../common/v5/constants';
import { V5 } from '../../common/path';
import { IsContextSearch, push } from '../../common/v5/utils';
import { resetManualErrandSelectedArea
	, deleteAllUploadadManualAttachments
} from './manual';
import {
	agentSetSidebarState,
	setAgentPreference,
	loadList,
	fetchQueueBasicErrandInfo
} from './async/workflow';
import {
	changeArea,
	stopAutoSave,
	closeErrandView,
	forceCloseErrandView,
	fetchAgentWorkingOnSelectedErrands
} from './async/errand';
import {
	setPreviousErrand
} from './errand';
import {
	getManualState
	, getManualCallState
	, isCallMemoize, isSelectedAreaValidForCallMemoize
} from '../selectors/manual';
import {
	actionMap
	, getMyId
	, getTotalSelectedErrands
	, showMobileView
} from '../selectors/workflow';
import {
	selectErrandSearchResult,
	selectAllErrandsSearchResult
} from './search';
import { resetOutboundErrandId } from './call';
import { uuidv4 } from '../../common/helpers';

export const {
	[UPDATE_PRIORITY_SORT]: updatePrioritySort
} = actionMap;

export const normalizeAgents = () => ({type: NORMALIZE_AGENTS});

export const changeErrandListSelection = (id, select) => ({
	type: SELECT_ERRAND_IN_ERRAND_LIST,
	payload: {id, select}
});

export const changeSelectedFilteredAreas = (context, value) => ({
	type: CHANGE_SELECTED_FILTER_AREAS
	, payload: {context, value}
});

const checkErrandSelection = (id, select) => (dispatch, getState) => {
	return new Promise((resolve, reject) => {
		if (id) {
			let currentContext = getState().app.workflow.filter.currentContext;
			if(IsContextSearch(currentContext)){
				return resolve(dispatch(selectErrandSearchResult(id, select)));
			}
			resolve(dispatch(changeErrandListSelection(id, select)));
		} else {
			resolve();
		}
	})
	.then(() => {
		let totalSelected = getTotalSelectedErrands(getState());
		if(totalSelected > 0){
			dispatch(showMultipleActions(true));
		}else{
			dispatch(showMultipleActions(false));
		}
		dispatch(fetchAgentWorkingOnSelectedErrands());
		dispatch(closeErrandView());
	});
};

export const selectErrandFromList = (id, select) => (dispatch, getState) => {
	const app = getState().app
		, currentID = app.errand.currentErrand.id
		, totalSelected = getTotalSelectedErrands(getState())
		;
	return new Promise((resolve, reject) => {
		if (id
			// unselect the last selected errand
			&& ((!select && (totalSelected === 1 || currentID === id))
				// select more than 1 errand
				|| (select && totalSelected > 0))) {
			//conscious act of selecting an errand will wipe the previous id
			if(select && totalSelected >= 1 ){
				dispatch(setPreviousErrand(0));
			}
			resolve(dispatch(closeErrandView()));
		} else {
			resolve();
		}
	})
	.then(() => dispatch(checkErrandSelection(id, select)))
	.catch(() => {/* rejected close view*/});
};

export const selectAllErrands = (ctx, selectAll) => (dispatch) => {
	if(selectAll){
		dispatch(showMultipleActions(true));
	}else{
		dispatch(showMultipleActions(false));
	}
	if (ctx == CTX_NEW && selectAll) {
		dispatch({type: SELECT_ALL_ERRANDS_WITHOUT_CHAT, payload: selectAll});
	} else {
		dispatch({type: SELECT_ALL_ERRANDS_IN_ERRAND_LIST, payload: selectAll});
	}

	dispatch(fetchAgentWorkingOnSelectedErrands());
};

export const setWorkflowReady = () => ({type: SET_WORKFLOW_READY});

export const selectArea = (id, select) => ({
	type: SELECT_AREA_IN_AREA_LIST,
	payload: {id, select}
});

export const selectAllAreas = selectAll => ({
	type: SELECT_ALL_AREAS,
	payload: selectAll
});

export const selectOrToggleSort = (sortOrToggle, index) => ({
	type: SELECT_TOGGLE_SORT_ERRAND_LIST,
	payload: {value: sortOrToggle, index}
});

export const selectTogglePriorityFilter = (toggle, show) => ({
	type: SELECT_TOGGLE_PRIORITY_FILTER,
	payload: {current: toggle, show}
});

export const selectToggleAreasFilter = (toggle, show) => ({
	type: SELECT_TOGGLE_AREAS_FILTER,
	payload: {current: toggle, show}
});

export const selectToggleAgentsFilter = (toggle, show) => ({
	type: SELECT_TOGGLE_AGENTS_FILTER,
	payload: {current: toggle, show}
});

export const selectToggleTagsFilter = (toggle, show) => ({
	type: SELECT_TOGGLE_TAGS_FILTER,
	payload: {current: toggle, show}
});

export const showAllArea = () => ({
	type: SHOW_ALL_AREA_IN_AREA_FILTER,
	payload: {}
});

export const changeContext = (context, params) => dispatch =>
	dispatch(proceedChangeContext(context, params)).catch(() => {});

const proceedChangeContext = (context, params) => (dispatch) =>
	new Promise((resolve, reject) => {
		resolve(dispatch(doChangeContext(context)));
	})
	.then(() => {
		dispatch(resetWorkflowFilter());
		if(typeof params !== "undefined"){
			dispatch(setListParams(params));
			dispatch(setSelectedFolder(params.folder));
			dispatch(setSelectedAgent(0));
		}
		if(!IsContextSearch(context)){
			dispatch(loadList("proceedChangeContext"));
		}
	});

const doChangeContext = (context) => (dispatch) => {
	if(IsContextSearch(context)){
		dispatch(showMultipleActions(false));
		dispatch(selectAllErrandsSearchResult(false));
	} else {
		dispatch(selectAllErrands(context, false));
	}
	dispatch({type: CHANGE_CONTEXT, payload: {value: context}});
};

export const setMyErrandFolder = (id) => ({
	type: SET_MY_ERRANDS_FOLDER,
	payload: {value: id}
});

export const setSelectedFolder = (id) => ({
	type: SET_SELECTED_FOLDER,
	payload: {value: id}
});

export const selectToggleSideBar = (toggle) => (dispatch, getState) => {
	dispatch({type: SELECT_TOGGLE_SIDEBAR, payload: {value: toggle}});
	dispatch(setAgentPreference({sideBarHidden: !toggle}));
}

export const selectCollapseSideBar = (toggle) => (dispatch, getState) => {
	dispatch({type: SELECT_COLLAPSE_SIDEBAR, payload: {value: toggle}});
	dispatch(setAgentPreference({sideBarCollapse: toggle}));
}

export const selectLaunchpadLayout = (layout, save) => (dispatch) => {
	dispatch({type: SELECT_LAUNCHPAD_LAYOUT, payload: {value: layout}});
	if(save) {
		dispatch(setAgentPreference({launchpadLayout: layout}));
	}
}

export const setLaunchpadWidgets = (prefs) => (dispatch, getState) => {
	const prefStrings = JSON.stringify(prefs);
	dispatch(setAgentPreference({launchpadWidgets: prefStrings})).then((data) => {
		if(data && data.launchpadWidgets) {
			const prefs = data.launchpadWidgets;
			dispatch({type: UPDATE_LAUNCHPAD_WIDGET, payload: {value: prefs}});
		}
	});
}

export const addingLaunchpadWidget = (id) => (dispatch, getState) => {
	const wfs = getState().app.workflow.fetchWfSettings.data;
	const defaultWidgets = wfs.defaultLaunchpadWidgets ? wfs.defaultLaunchpadWidgets : [];
	const currentWidgets = wfs.launchpadWidgets ? wfs.launchpadWidgets : [];
	const widget = defaultWidgets.find((w) => w.id == id);
	widget.id = uuidv4();
	widget.position = currentWidgets.length+1;
	widget.new = true;
	dispatch({type: ADD_LAUNCHPAD_WIDGET, payload: {value: widget}});
}

export const deleteLaunchpadWidget = (id) => (dispatch) => {
	dispatch({type: DELETE_LAUNCHPAD_WIDGET, payload: {value: id}});
}

export const setLaunchpadGrid = (layout, grid, save) => (dispatch) => {
	const layoutGridStr = JSON.stringify(grid.lg);
	dispatch({type: UPDATE_LAUNCHPAD_GRID_LAYOUT, payload: {
		launchpadGridLayout: layoutGridStr
		, launchpadLayout: layout
	}});
	if(save) {
		dispatch(setAgentPreference({
			launchpadGridLayout: layoutGridStr,
			launchpadLayout: layout
		}));
	}
}

export const setSelectedAgent = (id) => ({
	type: SET_SELECTED_AGENT,
	payload: {value: id}
});

export const setSelectedTags = (ids, add) => ({
	type: SET_SELECTED_TAGS,
	payload: {value: ids, addAction: add}
});

export const setMobileView = (toggle, height) => (dispatch, getState) => {
	if (showMobileView(getState()) !== toggle) {
		if(toggle) {
			dispatch(selectToggleSideBar(true));
		} else if(getState().app.workflow.showSideBar) {
			dispatch(selectToggleSideBar(false));
		}
	}
	dispatch({type: SET_MOBILE_VIEW, payload: {value: toggle, height: height}});
};

export const setErrandMobileView = (toggle) => ({
	type: SET_ERRAND_MOBILE_VIEW,
	payload: {value: toggle}
});

export const setListParams = (params) => ({
	type: UPDATE_WF_PARAMS,
	payload: params
});

export const setListReady = (ready) => ({
	type: SET_LIST_READY,
	payload: {value: ready}
});

export const setWfLimitedSettings = data => ({
	type: SET_WFSETTINGS_FROM_LOCAL,
	payload: {data}
});

export const setAgentDataFromLocal = data => ({
	type: SET_AGENTDATA_FROM_LOCAL,
	payload: {data}
});

export const clearManualErrandInputs = () => ({
	type: CLEAR_MANUAL_ERRAND_INPUTS
});

export const clearManualCallInputs = () => ({
	type: CLEAR_MANUAL_CALL_INPUTS
});

export const manualErrandState = (state, id, eid, cipherKey) => ({
	type: MANUAL_ERRAND_BUSY,
	payload: {state, id, eid, cipherKey}
});

export const manualCallState = (state, id, eid, cipherKey, extRefId) => ({
	type: MANUAL_CALL_BUSY,
	payload: {state, id, eid, cipherKey, extRefId}
});

export const toggleWorkflowPopup = (which, value) => (dispatch, getState) => {
	const state = getState()
		, meState = getManualState(state)
		, callState = getManualCallState(state)
		, wf = state.app.workflow.fetchWfSettings.data
		, prefManualErrandArea = wf.manualErrandPreferredArea
		;
	let manualState = meState, isCall = false;
	if(which === WFP_MANUAL_CALL && value !== MP_NONE) {
		dispatch({type: TOGGLE_WF_POPUP, payload: {which: WFP_MANUAL_CALL, value: MP_BASIC_CALL}});
	} else if(which === MP_MINIMIZE) {
		dispatch({type: TOGGLE_WF_POPUP, payload: {which: WFP_MANUAL_CALL, value: MP_MINIMIZE}});
	} else if(which === WFP_BULK_SEND_ERRAND) {
		dispatch({type: TOGGLE_WF_POPUP, payload: {which: WFP_BULK_SEND_ERRAND, value: value}});
	} else {
		if(which === WFP_MANUAL_CALL) {
			manualState = callState;
			isCall = true;
		}
		if (manualState === ME_ST_BUSY) {
			// do nothing for closing manual errand if the manual errand busy.
			if(isCall && value === MP_NONE) {
				dispatch(clearManualCallInputs());
				dispatch(manualCallState(ME_ST_IDLE));
			}
			return;
		} else if (manualState === ME_ST_CREATED) {
			if(isCall) {
				dispatch(clearManualCallInputs());
				dispatch(manualCallState(ME_ST_IDLE));
			} else {
				dispatch(clearManualErrandInputs());
				dispatch(manualErrandState(ME_ST_IDLE));
			}
		}
		dispatch({type: TOGGLE_WF_POPUP, payload: {which: (isCall ? WFP_MANUAL_CALL : which), value}});
		if (prefManualErrandArea > 0) {
			dispatch(changeArea(prefManualErrandArea, true));
		}
		//dispatch(setFilteredAreas([prefManualErrandArea]));
		if (value === MP_CALL || value === MP_BASIC_CALL) {
			if (!isSelectedAreaValidForCallMemoize(state)) {
				dispatch(resetManualErrandSelectedArea());
				dispatch(deleteAllUploadadManualAttachments(emptyArray));
			}
		}
	}
};

export const toggleManualOrCallPopup = (value, isCall) => toggleWorkflowPopup(
	(isCall ? WFP_MANUAL_CALL : WFP_MANUAL_ERRAND)
	, value
);

// NOTE: most likely using this function is wrong. If you need to clear the
// opened errand view, please trigger close errand view action because we need
// to handle the decision whether any changes done on the errand need to save or
// not.
export const resetErrandView = toggle => ({
	type: RESET_ERRAND_VIEW,
	payload: toggle
});

export const controlMultipleActions = (actionFor, show) => ({
	type: CONTROL_ERRAND_MULTIPLE_ACTION,
	payload: {actionFor, show}
});

//Back to workflow view and reset everything (opened errand, selections);
export const resetWorkflowView = () => (dispatch) => {
	//todo: maybe should just change to workflow view rather than using push
	dispatch(push(V5)).then(() => {
		dispatch(closeErrandView());
		dispatch(selectAllErrands("", false));
		dispatch(selectAllErrandsSearchResult(false));
	});
};

export const setFilteredTags = tags => ({
	type: SET_FILTERED_TAGS,
	payload: tags
});

export const setFilteredAreas = areas => ({
	type: SET_FILTERED_AREAS,
	payload: areas
});

export const resetWorkflowFilter = () => ({
	type: RESET_WORKFLOW_FILTER,
	payload: ""
});

// TODO: this action which suppose to change a state in reducer that affect the
// popup of multiple actions toolbar is not 'independent' enough to be its own
// state. The decision to show the popup can be derived from total number of
// selected errands and also if any open errand.
export const showMultipleActions = (show)  => ({
	type: SHOW_WORKFLOW_MULTIPLE_ACTIONS,
	payload: show
});

export const changeErrandListViewMode = (view) => ({
	type: CHANGE_ERRAND_LIST_VIEW_MODE,
	payload: view
});

export const setErrandMsgTruncatedByDefault = (toggle) => ({
	type: SET_ERRAND_MESSAGE_TRUNCATE,
	payload: toggle
});

export const changeParamsThenReload = (params) => (dispatch) => {
	return new Promise((resolve, reject) => {
		// TODO: no need promise here. useless.
		resolve(dispatch(setListParams(params)));
	})
	.then(() => {
		dispatch(loadList("changeParamsThenReload"));
	});
}

export const setSearchPreviewId = id => ({
	type: SET_SEARCH_PREVIEW_ID,
	payload: id
});

export const contextChangeByFolder = (
	toContext
	, params
) => (dispatch, getState) => {
	const state = getState();
	if (showMobileView(state)) {
		dispatch(selectToggleSideBar(false));
	}
	if (!params) {
		// 'new context' is the easiest parameter setup when it is unknown
		// which context switch to which happen when URL change from non-v5 to
		// v5 path.
		toContext = CTX_NEW;
		params = {
			folder: 0
			, source: CTX_NEW
			, classification: ""
			, user: getMyId(state)
		};
	}
	return dispatch(changeContext(toContext, params));
};

export const sendSessionReady = () => (dispatch, getState) => {
	if(features["telavox"] == true){
		let params = {}
		let apiparam = {};
		apiparam.action = "user-session-ready";
		apiparam.params = params;
		parent.postMessage(apiparam,"*");
	}
	return;
};

export const setSubscribeErrandCountPM = (val) => ({
	type: PM_SUBCRIBE_ERRAND_COUNT,
	payload: {value: val}
});

export const setPostMsgSubscribeErrandCount = (val) => (dispatch, getState) => {
	const state = getState();
	let pmArr = state.app.workflow.ui.subscribeErrandCountPM;
	for (const sub of pmArr) {
		if(Object.is(sub.source, val.source) == true){;
			return;
		}
	}
	pmArr.push(val);
	dispatch(setSubscribeErrandCountPM(pmArr));
};

export const fetchQueueErrands = (msg) => (dispatch) => {
	const parseMsg = JSON.parse(msg);
	if (parseMsg.channel == "call") {
		console.info("ignoring call channel event:", parseMsg);
			return
	}
	if (isNaN(parseInt(parseMsg.errand_id))) {
		console.info("ignoring invalid errand_id:", parseMsg);
			return;
	}
	dispatch({type: "QUEUE_ERRAND_EVENT", payload: parseMsg});
	return dispatch(fetchQueueBasicErrandInfo(parseInt(parseMsg.errand_id)));
}
